import axios, { AxiosInstance } from 'axios';
import {
  getGiteaRequestOptions,
  GiteaIntegrationConfig,
} from '@backstage/integration';

export const createGiteaClient = (
  config: GiteaIntegrationConfig,
  token?: string,
): AxiosInstance => {
  const options = getGiteaRequestOptions(config);
  const apiBaseUrl = `${config.baseUrl}/api/v1`;

  const headers = {
    ...options.headers,
    ...(token ? { Authorization: `token ${token}` } : {}),
  };

  return axios.create({
    baseURL: apiBaseUrl,
    headers: headers,
  });
};
