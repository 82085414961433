import { createApiRef } from '@backstage/core-plugin-api';
import { createGiteaClient } from '../client';
import { AxiosInstance } from 'axios';

/** @internal */
export type Repository = {
  name: string;
  locationHostname: string;
};

/** @internal */
export type PRUser = {
  id: number;
  login: string;
  full_name: string;
  email: string;
  avatar_url: string;
  html_url: string;
};

/** @internal */
export type Assignee = PRUser;

/** @internal */
export type Label = {
  id: number;
  name: string;
  color: string;
  description: string;
};

/** @internal */
export type PullRequest = {
  id: number;
  url: string;
  html_url: string;
  number: number;
  user: PRUser;
  title: string;
  body: string;
  state: 'open' | 'closed'; // State of the pull request
  created_at: string; // ISO date string
  updated_at: string; // ISO date string
  labels: Array<Label>;
  assignees: Array<Assignee>;
  comments: number;
  additions: number;
  deletions: number;
  changed_files: number;
  merged: boolean;
  merge_commit_sha: string;
  merged_by?: PRUser;
  requested_reviewers?: Array<PRUser>;
};

/** @internal */
export type RepoPullRequests = {
};

/** @internal */
export type PullRequestsByRepo = Record<string, RepoPullRequests>; // Maps repository names to their pull requests

/** @internal */
export type GiteaPRApi = ReturnType<typeof giteaPRApi>;

/**
 * @public
 */
export interface GiteaPRFilters {
  state?: 'open' | 'closed' | 'all';
  sort?: 'created' | 'updated' | 'comments';
  direction?: 'asc' | 'desc';
}

/** @internal */
export const giteaPRApiRef = createApiRef<GiteaPRApi>({
  id: 'plugin.gitea.pr.service',
});

/** @internal */
export const giteaPRApi = (
  token: string,
  host: string,
  baseUrl: string,
) => {
  
  const getGiteaClient = async (): Promise<AxiosInstance> => {
    const { config } = { config: { host, baseUrl } };
    return createGiteaClient(config, token);
  };

  const fetchPRsByRepoFromGitea = async (
    repos: Array<Repository>,
    itemsPerRepo: number,
    hostname: string,
    {
      state,
      sort,
      direction,
    }: GiteaPRFilters = {},
  ): Promise<PullRequestsByRepo> => {
    const client = await getGiteaClient();
    const safeNames: Array<string> = [];
    const repositories = repos
      .map(repo => {
        const [owner, name] = repo.name.split('/');

        const safeNameRegex = /-|\./gi;
        let safeName = name.replace(safeNameRegex, '');

        while (safeNames.includes(safeName)) {
          safeName += 'x';
        }

        safeNames.push(safeName);

        return {
          safeName,
          name,
          owner,
        };
      });

    const prsByRepo: PullRequestsByRepo = {};
    try {
      if (repositories.length === 0) {
        throw new Error(`No repositories found for ${hostname}`);
      }

      for (const repo of repositories) {
        const url = `/repos/${repo.owner}/${repo.name}/pulls`;
        const response = await client.get(url, {
          params: {
            limit: itemsPerRepo,
            state,
            sort,
            direction,
          },
        });

        prsByRepo[repo.safeName] = response.data;
      }
    } catch (e) {
      throw new Error(`Error fetching pull request info: ${e}`);
    }

    return repositories.reduce((acc, { safeName, name, owner }) => {
      if (prsByRepo[safeName]) {
        acc[`${owner}/${name}`] = prsByRepo[safeName];
      }

      return acc;
    }, {} as PullRequestsByRepo);
  };

  return { fetchPRsByRepoFromGitea };
};

function formatFilterValue(
  value: GiteaPRFilters[keyof GiteaPRFilters],
): string {
  return typeof value === 'string' ? `\"${value}\"` : `${value}`;
}

/** @internal */
export function createFilterByClause(filterBy?: GiteaPRFilters): string {
  if (!filterBy) {
    return '';
  }

  return Object.entries(filterBy)
    .filter(value => value)
    .map(([field, value]) => `${field}: ${formatFilterValue(value)}`)
    .join(', ');
}
