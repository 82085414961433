import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const giteaActionsPlugin = createPlugin({
  id: 'gitea-actions',
  routes: {
    root: rootRouteRef,
  },
});

export const GiteaActionsPage = giteaActionsPlugin.provide(
  createRoutableExtension({
    name: 'GiteaActionsPage',
    component: () =>
      import('./components/GiteaActionsPage').then(m => m.GiteaActionsPage),
    mountPoint: rootRouteRef,
  }),
);
