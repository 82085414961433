import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const giteaInsightsPlugin = createPlugin({
  id: 'gitea-insights',
  routes: {
    root: rootRouteRef,
  },
});

export const GiteaInsightsPage = giteaInsightsPlugin.provide(
  createRoutableExtension({
    name: 'GiteaInsightsPage',
    component: () =>
      import('./components/InsightPage').then(m => m.InsightsPage),
    mountPoint: rootRouteRef,
  }),
);
