import React from "react";
import { AppTheme } from "@backstage/core-plugin-api";
import {
  UnifiedThemeProvider,
} from "@backstage/theme";
import LightIcon from "@material-ui/icons/WbSunny";
import DarkIcon from "@material-ui/icons/Brightness2";

import { useThemeConfig } from "../hooks/useThemeConfig";
import { useTheme } from "../hooks/useTheme";


const createThemeProviderForThemeName = (
  themeName: string,
): AppTheme["Provider"] =>
  function RHDHThemeProviderForThemeName({ children }) {
    const themeConfig = useThemeConfig(themeName);
    const theme = useTheme(themeConfig);
    return (
      <UnifiedThemeProvider theme={theme}>{children}</UnifiedThemeProvider>
    );
  };

export const getThemes = (): AppTheme[] => {
  return [
    {
      id: "light",
      title: "Light",
      variant: "light",
      icon: <LightIcon />,
      Provider: createThemeProviderForThemeName("light"),
    },
    {
      id: "dark",
      title: "Dark",
      variant: "dark",
      icon: <DarkIcon />,
      Provider: createThemeProviderForThemeName("dark"),
    },
  ];
};
