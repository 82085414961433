import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { giteaPRApi, giteaPRApiRef } from './api';

/** @public */
const giteaPRsPlugin = createPlugin({
  id: 'gitea-pull-requests-board',
  apis: [
    createApiFactory({
      api: giteaPRApiRef,
      deps: {
        configApi: configApiRef,
      },
      factory: () => {        
        const host = '';
        const baseUrl = '';
        const token = ''; 
        return giteaPRApi(token, host, baseUrl);
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

/** @public */
export const GiteaPRsPage =
giteaPRsPlugin.provide(
    createRoutableExtension({
      name: 'GiteaPRsPage',
      component: () =>
        import('./components/GiteaPRs').then(
          m => m.GiteaPullRequests,
        ),
      mountPoint: rootRouteRef,
    }),
  )

export { giteaPRsPlugin };