import { createApiRef } from '@backstage/core-plugin-api';
import {createGiteaClient } from '../client';
import { AxiosInstance } from 'axios';

/** @internal */
export type Repository = {
  name: string;
  locationHostname: string;
};

/** @internal */
export type Assignee = {
  id: number,
  login: string;
  login_name: string;
  source_id: number;
  full_name: string;
  email: string;
};

/** @internal */
export type IssueUser = {
  id: number,
  login: string;
  login_name: string;
  source_id: number;
  full_name: string;
  email: string;
};

/** @internal */
export type Issue = {
  id: number;
  url: string;
  html_url: string;
  number: number;
  user: IssueUser; // Updated to include all user-related properties
  original_author: string;
  original_author_id: number;
  title: string;
  body: string;
  state: 'open' | 'closed'; // Based on the states available in your response
  created_at: string; // ISO date string
  updated_at: string; // ISO date string
  comments: number;
  repository:  Repository;
  assignees: Array<Assignee>;
};

/** @internal */
export type RepoIssues = {
};

/** @internal */
export type IssuesByRepo = Record<string, RepoIssues>; // Maps repository names to their issues

/** @internal */
export type GiteaIssuesApi = ReturnType<typeof giteaIssuesApi>;

/**
 * @public
 */
export interface GiteaIssuesFilters {
  assignee?: string;
  createdBy?: string;
  labels?: string[];
  mentioned?: string;
  milestone?: string;
  states?: ('OPEN' | 'CLOSED')[];
}
export interface GiteaIssuesOrdering {
  field: 'CREATED_AT' | 'UPDATED_AT' | 'COMMENTS';
  direction?: 'ASC' | 'DESC';
}
/**
 * @public
 */
export interface GiteaIssuesByRepoOptions {
  filterBy?: GiteaIssuesFilters;
  orderBy?: GiteaIssuesOrdering;
}

/** @internal */
export const giteaIssuesApiRef = createApiRef<GiteaIssuesApi>({
  id: 'plugin.giteaissues.service',
});

/** @internal */
export const giteaIssuesApi = (
  token: string,
  host: string,
  baseUrl: string,
) => {
  
  const getGiteaClient = async (): Promise<AxiosInstance> => {
    const { config } = { config: { host, baseUrl } };
    return createGiteaClient(config, token);
  };

  const fetchIssuesByRepoFromGitea = async (
    repos: Array<Repository>,
    itemsPerRepo: number,
    hostname: string,
    {
      
    }: GiteaIssuesByRepoOptions = {},
  ): Promise<IssuesByRepo> => {
    const client = await getGiteaClient();
    const safeNames: Array<string> = [];
    const repositories = repos
      .map(repo => {
        const [owner, name] = repo.name.split('/');

        const safeNameRegex = /-|\./gi;
        let safeName = name.replace(safeNameRegex, '');

        while (safeNames.includes(safeName)) {
          safeName += 'x';
        }

        safeNames.push(safeName);

        return {
          safeName,
          name,
          owner,
        };
      });

    const issuesByRepo: IssuesByRepo = {};
    try {
      if (repositories.length === 0) {
        throw new Error(`No repositories found for ${hostname}`);
      }

      for (const repo of repositories) {
        const url = `/repos/${repo.owner}/${repo.name}/issues`;

        const response = await client.get(url, {
          params: {
            limit: itemsPerRepo,
          },
        });

        issuesByRepo[repo.safeName] = response.data;
      }
    } catch (e) {
      throw new Error(`Error fetching issue info: ${e}`);
    }

    return repositories.reduce((acc, { safeName, name, owner }) => {
      if (issuesByRepo[safeName]) {
        acc[`${owner}/${name}`] = issuesByRepo[safeName];
      }

      return acc;
    }, {} as IssuesByRepo);
  };

  return { fetchIssuesByRepoFromGitea };
};

function formatFilterValue(
  value: GiteaIssuesFilters[keyof GiteaIssuesFilters],
): string {
  if (Array.isArray(value)) {
    return `[ ${value.map(formatFilterValue).join(', ')}]`;
  }

  return typeof value === 'string' ? `\"${value}\"` : `${value}`;
}

/** @internal */
export function createFilterByClause(filterBy?: GiteaIssuesFilters): string {
  if (!filterBy) {
    return '';
  }

  return Object.entries(filterBy)
    .filter(value => value)
    .map(([field, value]) => {
      if (field === 'states') {
        return `${field}: ${value.join(', ')}`;
      }

      return `${field}: ${formatFilterValue(value)}`;
    })
    .join(', ');
}
