import {
  createPlugin,
  createApiFactory,
  createComponentExtension,
  createRoutableExtension,
  configApiRef,
} from '@backstage/core-plugin-api';
import { giteaIssuesApi, giteaIssuesApiRef } from './api';
import { rootRouteRef } from './routes';

/** @public */
export const giteaIssuesPlugin = createPlugin({
  id: 'gitea-issues',
  apis: [
    createApiFactory({
      api: giteaIssuesApiRef,
      deps: {
        configApi: configApiRef,
      },
      factory: () => {        
        const host = '';
        const baseUrl = '';
        const token = ''; 
        return giteaIssuesApi(token, host, baseUrl);
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

/** @public */
export const GiteaIssuesCard = giteaIssuesPlugin.provide(
  createComponentExtension({
    name: 'GiteaIssuesCard',
    component: {
      lazy: () => import('./components/GiteaIssues').then(m => m.GiteaIssues),
    },
  }),
);

/** @public */
export const GiteaIssuesPage = giteaIssuesPlugin.provide(
  createRoutableExtension({
    name: 'GiteaIssuesPage',
    component: () =>
      import('./components/GiteaIssues').then(m => m.GiteaIssues),
    mountPoint: rootRouteRef,
  }),
);
