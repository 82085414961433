import React from "react";
import { type UnifiedThemeOptions } from "@backstage/theme";
import type { ThemeConfig } from "../types";
import * as rhdh from "../themes/rhdh";
import { migrateThemeConfig } from "../utils/migrateTheme";
import { mergeUnifiedThemeOptions } from "../utils/mergeTheme";
import { createPageThemes } from "../utils/createPageThemes";

/** Creates a memorized Backstage UnifiedThemeOptions based on the given ThemeConfig. */
export const useThemeOptions = (
  themeConfig: ThemeConfig,
): UnifiedThemeOptions => {
  const theme = React.useMemo<UnifiedThemeOptions>(() => {
    const mode = themeConfig.mode ?? "light";
    const variant = themeConfig.variant ?? "rhdh";

    let defaultThemeConfig: ThemeConfig;
    defaultThemeConfig = rhdh.getDefaultThemeConfig(mode);
    
    const migratedThemeConfig = migrateThemeConfig(themeConfig);
    const mergedThemeConfig = mergeUnifiedThemeOptions(
      defaultThemeConfig,
      migratedThemeConfig,
    );

    const unifiedThemeOption: UnifiedThemeOptions = {
      palette: mergedThemeConfig.palette as UnifiedThemeOptions["palette"],
      defaultPageTheme: mergedThemeConfig.defaultPageTheme,
      fontFamily: mergedThemeConfig.fontFamily,
      htmlFontSize: mergedThemeConfig.htmlFontSize,
      typography: mergedThemeConfig.typography,
    };

    unifiedThemeOption.pageTheme = createPageThemes(mergedThemeConfig);

    if (variant !== "backstage") {
      unifiedThemeOption.components = rhdh.components(mergedThemeConfig);
    }

    return unifiedThemeOption;
  }, [themeConfig]);
  return theme;
};
